import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LeadsState } from '../../leads/store/leads.state';
import {
  getLeadTimeline,
  getPurchasesLeadsAction,
  setSelectedLead
} from '../../leads/store/leads.actions';
import { Lead } from '@models/lead.interface';
import { Observable } from 'rxjs';
import { getLeads } from '../../leads/store/leads.selectors';
import { ProjectEntity } from 'src/app/client';

@Component({
  selector: 'app-purchases-page',
  template: `
    <app-tabs
      [tabs]="[
        { label: 'Lead', value: 'lead' },
        { label: 'Opportunità', value: 'opportunity' }
      ]"></app-tabs>
    <div class="h-20 border-b border-gray flex items-center p-2 mb-4 px-5">
      <button class="border rounded-full px-2 py-1">Aggiungi Lead</button>
    </div>

    <app-leads-list
      (onClick)="onLeadClick($event)"
      (onLeadIconClick)="onLeadIconClick($event)"
      [leads]="(leads$ | async) ?? []"></app-leads-list>
  `,
})
export class PurchasesPageComponent implements OnInit {
  leads$: Observable<Array<ProjectEntity>>;
  constructor(public router: Router, private store: Store<LeadsState>) {}

  ngOnInit() {
    this.store.dispatch(getPurchasesLeadsAction());
  }
  onLeadClick(lead: ProjectEntity) {
  //  this.store.dispatch(setSelectedLead({ lead }));
    this.router.navigate(['sales/detail/' + lead._id]);
  }

  onLeadIconClick(lead: ProjectEntity) {
    //this.store.dispatch(getLeadTimeline({ lead }));
  }
}
